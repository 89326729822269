<template>
	<main>
		<div id="main">
		
			<section id="visual">
				<div class="inner">
					<div class="tit">
						<span>다시 없을 기회, 새로운 인생</span>
						<strong>특화조직구축 프로젝트</strong>
					</div>
				</div>
			</section>
			
			<section id="definition">
				<div class="inner">
					<div class="tit">
						<strong>특화조직구축<br>프로젝트란?</strong>
						<span><b>한화생명 계열사로서 체계적인 관리 시스템과 GA시장을 선도하는 피플라이프의 영업 노하우</b>로 GA업계 최고 수준의 사업단장으로 육성하겠다는 프로젝트입니다.</span>
					</div>
					<div class="contents">
						<ul>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/definition_img_1.png" alt="">
								</div>
								<p>
									<span>PROJECT</span>
									<strong>
										피플라이프<br>
										영업노하우
									</strong>
								</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/definition_img_2.png" alt="">
								</div>
								<p>
									<span>SCOUT</span>
									<strong>
										차별화된<br>
										보상과 지원
									</strong>
								</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/definition_img_3.png" alt="">
								</div>
								<p>
									<span>HANWHA</span>
									<strong>
										한화생명 계열사로서<br>
										체계적인 관리 시스템
									</strong>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			
			<section id="target">
				<div class="inner">
					<div class="tit">
						<em>특화조직구축 프로젝트</em>
						<strong>지원대상·조건</strong>
					</div>
					<div class="contents">
						<ul>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/target_img_1.png" alt="">
								</div>
								<p>생명·손해보험사<br>영업관리자</p>
								<span>5년 이상 경력자 (자회사형 GA 포함)</span>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/target_img_2.png" alt="">
								</div>
								<p>지점장, 단장, 본부장 등<br>정규직 또는<br>사업가형 관리자 경력</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/target_img_3.png" alt="">
								</div>
								<p>영업경력, 관리능력,<br>인적 네트워크 필수</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			
			<section id="merit">
				<div class="inner">
					<div class="tit">
						<em>특화조직구축 프로젝트</em>
						<strong>장점 및 특전</strong>
						<span><b>GA시장을 선도하는 피플라이프에서</b><br><b>능력있는</b> 영업관리자를 모십니다.</span>
					</div>
					<div class="contents">
						<ul>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_1.png" alt="">
								</div>
								<p>영업관리자를 위한<br>특화된 조직 구축<br>프로젝트 지원</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_2.png" alt="">
								</div>
								<p>「기업형 GA 안착」을<br>위한 회사 주도의<br>안정적 영업환경 제공</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_3.png" alt="">
								</div>
								<p>조직 도입<br>비용 지원</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_4.png" alt="">
								</div>
								<p>영업 제반 비용<br>(임차료·총무 등) 지원</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_5.png" alt="">
								</div>
								<p>DB 및 병원영업지원 등의<br>FA 갈 곳 마련 지원</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_6.png" alt="">
								</div>
								<p>업계 최고의 고능률<br>FA교육·육성·지원 체계</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_7.png" alt="">
								</div>
								<p>차별화된 영업 관리자<br>보상 체계</p>
							</li>
							<li>
								<div class="img_area">
									<img src="/img/recruit/recruitSpecial/merit_img_8.png" alt="">
								</div>
								<p>GA 업계 최고 수준의<br>사업단장 육성 지원</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			
			<section id="movie">
				<div class="inner">
					<div class="tit">
						<em>특화조직구축 프로젝트</em>
						<strong>소개 영상</strong>
						<span><b>보험사 영업관리자 집중 스카웃 프로젝트</b>를<br>영상으로 직접 확인하세요!</span>
					</div>
					<div class="contents">
						<div class="video_wrap">
							<button class="videoLayer" data-src="https://www.youtube.com/embed/Atzbrw93G8k" data-title="보험 영업관리자 주목! 피플라이프 특화조직구축 프로젝트" data-type="youtube" type="button" title="영상재생">
								<img class="thumb" src="https://i.ytimg.com/vi/Atzbrw93G8k/maxresdefault.jpg" alt="스크린샷">
							</button>
						</div>
					</div>
				</div>
			</section>
			
			<section id="infomation">
				<div class="inner">
					<div class="tit">
						<strong>프로젝트에<br>합류하시겠습니까?</strong>
					</div>
					<div class="contents">
						<dl>
							<dt>문의</dt>
							<dd>
								<span><em>피플라이프 특화조직구축팀</em></span>
								<span>02-2162-7753 <i>·</i> 010-8210-8253</span>
							</dd>
							<dd>
								<span><em>채용 담당자</em></span>
								<span>정월환 팀장 (neotop21@peoplelife.co.kr)</span>
							</dd>
						</dl>
					</div>
				</div>
			</section>
			
			<section id="caution">
				<div class="inner">
					<div class="contents">
						<ul>
							<li>※ 본 광고는 채용을 위한 홍보용 자료로, 지원내용은 피플라이프 보험대리점의 공지문 및 수수료규정 등 제규정에 의거 적용됩니다.</li>
							<li>※ 본 광고는 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</li>
						</ul>
						<p>
							<span>
								<em><em>근무장소</em></em>
								<em>전국의 개인/법인 영업사업단 사무실</em>
							</span>
							<span>
								<em><em>점검번호</em></em>
								<em>피플-준법-제2024-12-5825호(24.12.17)</em>
							</span>
						</p>
					</div>
				</div>
			</section>

		</div>
	</main>
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitSpecial.scss';
</style>

<script>
    export default {}
</script>